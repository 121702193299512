import "./import-jquery";
import 'bootstrap';
// import 'fullpage.js'; 
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
// import PanelSnap from 'panelsnap';
import anime from 'animejs/lib/anime.es.js';
import {formInit} from './form.js'
// import 'jquery-scrollify'
import captain from 'url:../img/cosmocat-captain.png?as=webp'
import captain_story from 'url:../img/stories-captain.jpg'
import scientist from 'url:../img/cosmocat-scientist.png?as=webp'
import scientist_story from 'url:../img/stories-scientist.jpg'
import doctor from 'url:../img/cosmocat-doctor.png?as=webp'
import doctor_story from 'url:../img/stories-doctor.jpg'
import cook from 'url:../img/cosmocat-cook.png?as=webp'
import cook_story from 'url:../img/stories-cook.jpg'
import pilot from 'url:../img/cosmocat-pilot.png?as=webp'
import pilot_story from 'url:../img/stories-pilot.jpg'
import engineer from 'url:../img/cosmocat-engineer.png?as=webp'
import engineer_story from 'url:../img/stories-engineer.jpg'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

$(function(){
    //for fixing 1oovh on mobile
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
    // $.scrollify({
    //   section : ".section",
    //   setHeights: false,
    //   updateHash: false,
    //   offset: 5
    // });
    nav_init();
    activeMenuItem();
    // const panel_snap = new PanelSnap({
    //     container: document.body,
    //     panelSelector: ' .section',
    //     directionThreshold: 20,
    // });
    initLiquidButtons();
    // addExtraBubbles();
    buttonOverlay()
    initTest()
    animateImg()
    hoverVideo()
    scrollTo()
    formInit()

    // if ($(window).width() < 1400){
    //   // panel_snap.destroy();
    //   $.scrollify.destroy()
    // }
    makeResponsive();
    $( window ).on('resize', function() {
      if ($(window).width() < 1400){
        // panel_snap.destroy();
        // $.scrollify.destroy()
      }
      makeResponsive();
    });
    // $(document).on('scroll', function(){
    //   if ($('body').hasClass('nav-opened')){
    //     $('#open_nav').trigger('click');
    //   }
    // })
    $('.section-9 .block_img img').each(function(i, el){
      $(this).css({'animation-delay' : `${(i+1)*300}ms`})
    });
    if (!window.matchMedia('(hover: hover)').matches) {
      $('.product_info_btn').on('click', function(){
          $(this).next().show();
      });
      $('.product_info_popup .close_btn').on('click', function(){
          $(this).parent().hide();
      })
    }
    
    $('.product-carousel').owlCarousel({
      items: 1,
      dots: false,
      nav: true,
      navText: ['',''],
      loop: false,
      rewind: true
    });
    $('.test-carousel').owlCarousel({
      items: 3,
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        1040: {
          items: 3
        }
      },
      nav: true,
      margin: 20,
      navText: ['',''],
      loop: true
    });
    $('.section-8 .owl-carousel').owlCarousel({
      
      dots: false,
      nav: true,
      margin: 20,
      navText: ['',''],
      responsive: {
        0 : {
          items: 1,
          stagePadding: 0
      },
      375 : {
        items: 1,
        stagePadding: 0
      },
      
      768 : {
        items: 2,
      },
      1040 : {
        items: 3,
      },
      // breakpoint from 768 up
      1200 : {
          items: 4,
      }
      }
    });
});
function makeResponsive(){
  let padding_top = $('.logo img').height();
  $('.section:not(.section-2)').css({'padding-top': `${padding_top + 5}px`});
}
function scrollTo(){
  $('.menu .menu__main li a, .cta-btn').on('click', function(e){
    e.preventDefault();
    let target = $(this).attr('href');
    if ($(window).width() < 576){
      if (!$(e.target).hasClass('cta-btn')){
        $('#open_nav').trigger('click');
      } 
    }
    $('html, body').animate({
      scrollTop: $(target).offset().top
  }, 1000);
  })
}
function animateImg(){
  function getCurrentRotationFixed( el ) {
    var st = window.getComputedStyle(el, null);
    var tr = st.getPropertyValue("-webkit-transform") ||
         st.getPropertyValue("-moz-transform") ||
         st.getPropertyValue("-ms-transform") ||
         st.getPropertyValue("-o-transform") ||
         st.getPropertyValue("transform") ||
         "fail...";
  
    if( tr !== "none") {
    //   console.log('Matrix: ' + tr);
  
      var values = tr.split('(')[1];
        values = values.split(')')[0];
        values = values.split(',');
      var a = values[0];
      var b = values[1];
      var c = values[2];
      var d = values[3];
  
      var scale = Math.sqrt(a*a + b*b);
      var radians = Math.atan2(b, a);
      if ( radians < 0 ) {
        radians += (2 * Math.PI);
      }
      var angle = Math.round( radians * (180/Math.PI));
      /**/
      
    } else {
      var angle = 0;
    }
  
    // works!
    return angle;
  }
  // const $el2 = $('.section-2 .section_bg_img');
  // $el2.each(function(i, el){
  //   anime({
  //   targets: el,
  //   translateY: [
  //     {
  //       value: '-=20px'
  //     },
  //     {
  //       value: '+=20px'
  //     },
  //   ],
  //   translateX: {
  //     value: '-=0px'
  //   },
  //   rotate: [ `${getCurrentRotationFixed(el)}deg`,  `${getCurrentRotationFixed(el) + 10}deg`], 
  //   duration: 4000,
  //   loop: true,
    
  //   delay: anime.stagger(300),
  //   easing: 'easeInOutCubic'
  // });
  // });
  
  anime({
    targets: '.section-1 .star',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: [
      {
       value: function(el){return getCurrentRotationFixed(el) + 10},
       transformOrigin: 'center bottom'
      },
      {
        value: function(el){return getCurrentRotationFixed(el) - 10},
        transformOrigin: 'center bottom'
      },
      {
        value: function(el){return getCurrentRotationFixed(el)},
        transformOrigin: 'center bottom'
      },

    ],
      
    duration: 4000,
    loop: true,
    
    delay: anime.stagger(100),
    easing: 'easeInOutCubic'
  });
  anime({
    targets: ' .section-2 .section_bg_img',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: function(el){
     return [`${getCurrentRotationFixed(el)}deg`, `${getCurrentRotationFixed(el)}deg`]

    },
    duration: 4000,
    loop: true,
    // direction: 'alternate',
    delay: anime.stagger(300),
    easing: 'easeInOutCubic'
  });
  anime({
    targets: ' .section-3 .section_bg_img',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: function(el){
     return [`${getCurrentRotationFixed(el)}deg`, `${getCurrentRotationFixed(el)}deg`]

    },
    duration: 4000,
    loop: true,
    // direction: 'alternate',
    delay: anime.stagger(300),
    easing: 'easeInOutSine'
  });
  anime({
    targets: ' .section-6 .section_bg_img, .section-7 .section_bg_img',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: function(el){
     return [`${getCurrentRotationFixed(el)}deg`, `${getCurrentRotationFixed(el)}deg`]

    },
    duration: 4000,
    loop: true,
    // direction: 'alternate',
    delay: anime.stagger(300),
    easing: 'easeInSine'
  });
  anime({
    targets: ' .section-8 .section_bg_img',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: function(el){
     return [`${getCurrentRotationFixed(el)}deg`, `${getCurrentRotationFixed(el)}deg`]

    },
    duration: 4000,
    loop: true,
    // direction: 'alternate',
    delay: anime.stagger(300),
    easing: 'easeInSine'
  });
  anime({
    targets: ' .section-9 .section_bg_img',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: function(el){
     return [`${getCurrentRotationFixed(el)}deg`, `${getCurrentRotationFixed(el)}deg`]

    },
    duration: 4000,
    loop: true,
    // direction: 'alternate',
    delay: anime.stagger(300),
    easing: 'easeInSine'
  });
  anime({
    targets: ' .section-10 .section_bg_img',
    translateY: [
      {
        value: '-=20px'
      },
      {
        value: '+=20px'
      },
    ],
    translateX: {
      value: '-=0px'
    },
    rotate: function(el){
     return [`${getCurrentRotationFixed(el)}deg`, `${getCurrentRotationFixed(el)}deg`]

    },
    duration: 4000,
    loop: true,
    // direction: 'alternate',
    delay: anime.stagger(300),
    easing: 'easeInSine'
  });
  anime({
    targets: '.rocket',
    translateY:  ['-55%', '-60%'],
    translateX: ['-30%', '-30%'],
    rotate: {
     value: [function(el){return getCurrentRotationFixed(el) -2}, function(el){return getCurrentRotationFixed(el) + 2}],
     transformOrigin: 'center bottom'
    },
      
    duration: 2000,
    delay: function(){
      return anime.random(200, 400)
    },
    direction: 'alternate',
    loop: true,
    
    // delay: anime.random(500, 1000),
    easing: 'easeInOutSine'
  });
  anime({
    targets: '.cat',
    translateY:  ['-20%', '-25%'],
    translateX: ['50%', '50%'],
    rotate: {
     value: [function(el){return getCurrentRotationFixed(el) -2}, function(el){return getCurrentRotationFixed(el) + 2}],
     transformOrigin: 'center bottom'
    },
      
    duration: 2000,
    delay: function(){
      return anime.random(200, 400)
    },
    direction: 'alternate',
    loop: true,
    
    // delay: anime.random(500, 1000),
    easing: 'easeInOutSine'
  });
  anime({
    targets: '.ufo',
    translateY:  ['-70%', '-75%'],
    translateX: ['50%', '50%'],
    rotate: {
     value: [function(el){return getCurrentRotationFixed(el) -2}, function(el){return getCurrentRotationFixed(el) + 2}],
     transformOrigin: 'center bottom'
    },
      
    duration: 2000,
    delay: function(){
      return anime.random(200, 400)
    },
    direction: 'alternate',
    loop: true,
    
    // delay: anime.random(500, 1000),
    easing: 'easeInOutSine'
  });
}
function animateImages(){
  $('.section_bg_img:not(.section-2 .section_bg_img, .section-5 .section_bg_img)').each(function(){
    $(this).addClass('floating-animate').css({'animation-delay': `${(Math.random() * 5).toFixed(1)}s`, 'animation-duration': `${Math.floor(Math.random() * 5 + 5)}s`});
  });
}
function nav_init(){
    $('#open_nav').on('click', () =>{
        $('body, .menu').toggleClass('nav-opened');
    });
    // $('#close_nav').on('click', () =>{
    //     $('body, .menu').removeClass('nav-opened');
    // });
}

function generateRandomStyleForBubbles(){
    let left = Math.floor(Math.random() * 100);
    let scale = (Math.random() * 0.8).toFixed(1);
    let speed = Math.floor(Math.random() * 40);
    let delay = (Math.random() * 0.5).toFixed(1);
    return {
        left: `${left}%`,
        transform: `scale(${scale}, ${scale})`,
        animationDuration: `${speed}s`,
        animationIterationCount: 1,
        animationDelay: `${delay}s`
    }
}
var bubbles_fixed = false;
$(window).on('scroll', addExtraBubbles)
function addExtraBubbles() {
  // console.log($(document).scrollTop())
  // if (($(document).scrollTop() - 100 > $('.section-6').offset().top) && !bubbles_fixed){
  //   $('.bubbles').css({'position': 'absolute', 'width': '100%', 'top': `${$('.section-6').offset().top}px`});
  //   bubbles_fixed = true;
  //   console.log('absoluted')
  // }else if (($(document).scrollTop() < $('.section-6').offset().top) && bubbles_fixed){
  //   console.log('fixed')
  //   $('.bubbles').css({'position': 'fixed', 'top': `0px`, 'width': '100vw'});
  //   bubbles_fixed = false;
  // }

  if($('.extra-bubble').length < 100){
      for(let i=0; i <= 100 - $('.extra-bubble').length; i++){
          $('<li class="extra-bubble"></li>').css(generateRandomStyleForBubbles()).appendTo('.bubbles').on('animationend', function(){
              $(this).remove();
          });
      }
  }

}
function moveBubblesOnScroll(){
    var trashed = false;
    $(window).on('scroll', function(){
        if (!trashed){
            $('.bubbles').addClass('trashed')
            $('.bubbles.trashed').on('animationend', function(e){
                if ($(e.target).hasClass('trashed')){
                    
                    $(e.target).removeClass('trashed');
                    trashed = false;
                }
                
            });
            trashed = true;
        }
    })
}

function youtube(){
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    // var players = [];
    
    window.onYouTubeIframeAPIReady = function() {
      $('.player').each(function(i, el){
      
        let player = new YT.Player(el, {
            height: 'auto',
            width: '100%',
            videoId: $(this).data('video'),
            playerVars: {
              rel: 0,
              modestbranding: 0,
              showinfo: 0,
            },
            
            events: {
                'onStateChange': onPlayerStateChange
            }
        });
      });

    }


    function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING) {
          // console.log(players)
        } else {
        //player is paused
        }
    }

    // function stopVideo() {
    //     player.stopVideo();
    // }

    // function playVideo() {
    // player.playVideo();
    // }

    // function pauseVideo() {
    // player.pauseVideo();
    // }

    // $('#video-modal').on('shown.bs.modal', function () {
    //     playVideo();
    // })
    // $('#video-modal').on('hidden.bs.modal', function () {
    //     pauseVideo();
    // })
   
}
youtube();
function initLiquidButtons(){
    const LiquidButton = class LiquidButton {
        constructor(svg) {
          const options = svg.dataset;
          this.id = this.constructor.id || (this.constructor.id = 1);
          this.constructor.id++;
          this.xmlns = 'http://www.w3.org/2000/svg';
          this.tension = options.tension * 1 || 0.4;
          this.width   = options.width   * 1 || 200;
          this.height  = options.height  * 1 ||  50;
          this.margin  = options.margin * 1 ||  40;
          this.hoverFactor = options.hoverFactor || -0.1;
          this.gap     = options.gap     ||   5;
          this.debug   = options.debug   || false;
          this.forceFactor = options.forceFactor || 0.2;
          this.color1 = options.color1 || '#36DFE7';
          this.color2 = options.color2 || '#8F17E1';
          this.color3 = options.color3 || '#BF09E6';
          this.textColor = options.textColor || '#292456';
          this.textSize = options.textSize || '15px';
          this.text = options.text    || 'Button';
          this.svg = svg;
          this.layers = [{
            points: [],
            viscosity: 0.5,
            mouseForce: 100,
            forceLimit: 2,
          },{
            points: [],
            viscosity: 0.8,
            mouseForce: 150,
            forceLimit: 3,
          }];
          for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
            const layer = this.layers[layerIndex];
            layer.viscosity = options['layer-' + (layerIndex + 1) + 'Viscosity'] * 1 || layer.viscosity;
            layer.mouseForce = options['layer-' + (layerIndex + 1) + 'MouseForce'] * 1 || layer.mouseForce;
            layer.forceLimit = options['layer-' + (layerIndex + 1) + 'ForceLimit'] * 1 || layer.forceLimit;
            layer.path = document.createElementNS(this.xmlns, 'path');
            this.svg.appendChild(layer.path);
          }
          this.wrapperElement = options.wrapperElement || document.body;
          if (!this.svg.parentElement) {
            this.wrapperElement.append(this.svg);
          }
      
          this.svgText = document.createElementNS(this.xmlns, 'text');
          this.svgText.setAttribute('x', '50%');
          this.svgText.setAttribute('y', '50%');
          this.svgText.setAttribute('dy', '9px');
          this.svgText.setAttribute('font-size', this.textSize);
          this.svgText.style.fontFamily = 'RotondaC';
          this.svgText.setAttribute('text-anchor', 'middle');
          this.svgText.setAttribute('pointer-events', 'none');
          this.svgText.textContent = this.text;
          this.svgText.style.fill = this.textColor;
          this.svg.appendChild(this.svgText);
      
          this.svgDefs = document.createElementNS(this.xmlns, 'defs')
          this.svg.appendChild(this.svgDefs);
      
          this.touches = [];
          this.noise = options.noise || 0;
          document.body.addEventListener('touchstart', this.touchHandler);
          document.body.addEventListener('touchmove', this.touchHandler);
          document.body.addEventListener('touchend', this.clearHandler);
          document.body.addEventListener('touchcancel', this.clearHandler);
          this.svg.addEventListener('mousemove', this.mouseHandler);
          this.svg.addEventListener('mouseout', this.clearHandler);
          this.initOrigins();
          this.animate();
          this.svg.classList.add('lb-inited');
        }
      
        get mouseHandler() {
          return (e) => {
            this.touches = [{
              x: e.offsetX,
              y: e.offsetY,
              force: 1,
            }];
          };
        }
      
        get touchHandler() {
          return (e) => {
            this.touches = [];
            const rect = this.svg.getBoundingClientRect();
            for (let touchIndex = 0; touchIndex < e.changedTouches.length; touchIndex++) {
              const touch = e.changedTouches[touchIndex];
              const x = touch.pageX - rect.left;
              const y = touch.pageY - rect.top;
              if (x > 0 && y > 0 && x < this.svgWidth && y < this.svgHeight) {
                this.touches.push({x, y, force: touch.force || 1});
              }
            }
            e.preventDefault();
          };
        }
      
        get clearHandler() {
          return (e) => {
            this.touches = [];
          };
        }
      
        get raf() {
          return this.__raf || (this.__raf = (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function(callback){ setTimeout(callback, 10)}
          ).bind(window));
        }
      
        distance(p1, p2) {
          return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
        }
      
        update() {
          for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
            const layer = this.layers[layerIndex];
            const points = layer.points;
            for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
              const point = points[pointIndex];
              const dx = point.ox - point.x + (Math.random() - 0.5) * this.noise;
              const dy = point.oy - point.y + (Math.random() - 0.5) * this.noise;
              const d = Math.sqrt(dx * dx + dy * dy);
              const f = d * this.forceFactor;
              point.vx += f * ((dx / d) || 0);
              point.vy += f * ((dy / d) || 0);
              for (let touchIndex = 0; touchIndex < this.touches.length; touchIndex++) {
                const touch = this.touches[touchIndex];
                let mouseForce = layer.mouseForce;
                if (
                  touch.x > this.margin &&
                  touch.x < this.margin + this.width &&
                  touch.y > this.margin &&
                  touch.y < this.margin + this.height
                ) {
                  mouseForce *= -this.hoverFactor;
                }
                const mx = point.x - touch.x;
                const my = point.y - touch.y;
                const md = Math.sqrt(mx * mx + my * my);
                const mf = Math.max(-layer.forceLimit, Math.min(layer.forceLimit, (mouseForce * touch.force) / md));
                point.vx += mf * ((mx / md) || 0);
                point.vy += mf * ((my / md) || 0);
              }
              point.vx *= layer.viscosity;
              point.vy *= layer.viscosity;
              point.x += point.vx;
              point.y += point.vy;
            }
            for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
              const prev = points[(pointIndex + points.length - 1) % points.length]; 
              const point = points[pointIndex];
              const next = points[(pointIndex + points.length + 1) % points.length];
              const dPrev = this.distance(point, prev);
              const dNext = this.distance(point, next);
      
              const line = {
                x: next.x - prev.x,
                y: next.y - prev.y,
              };
              const dLine = Math.sqrt(line.x * line.x + line.y * line.y);
      
              point.cPrev = {
                x: point.x - (line.x / dLine) * dPrev * this.tension,
                y: point.y - (line.y / dLine) * dPrev * this.tension,
              };
              point.cNext = {
                x: point.x + (line.x / dLine) * dNext * this.tension,
                y: point.y + (line.y / dLine) * dNext * this.tension,
              };
            }
          }
        }
      
        animate() {
          this.raf(() => {
            this.update();
            this.draw();
            this.animate();
          });
        }
      
        get svgWidth() {
          return this.width + this.margin * 2;
        }
      
        get svgHeight() {
          return this.height + this.margin * 2;
        }
      
        draw() {
          for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
            const layer = this.layers[layerIndex];
            if (layerIndex === 1) {
              if (this.touches.length > 0) {
                while (this.svgDefs.firstChild) {
                  this.svgDefs.removeChild(this.svgDefs.firstChild);
                }
                for (let touchIndex = 0; touchIndex < this.touches.length; touchIndex++) {
                  const touch = this.touches[touchIndex];
                  const gradient = document.createElementNS(this.xmlns, 'radialGradient');
                  gradient.id = 'liquid-gradient-' + this.id + '-' + touchIndex;
                  const start = document.createElementNS(this.xmlns, 'stop');
                  start.setAttribute('stop-color', this.color3);
                  start.setAttribute('offset', '0%');
                  const stop = document.createElementNS(this.xmlns, 'stop');
                  stop.setAttribute('stop-color', this.color2);
                  stop.setAttribute('offset', '100%');
                  gradient.appendChild(start);
                  gradient.appendChild(stop);
                  this.svgDefs.appendChild(gradient);
                  gradient.setAttribute('cx', touch.x / this.svgWidth);
                  gradient.setAttribute('cy', touch.y / this.svgHeight);
                  gradient.setAttribute('r', touch.force);
                  layer.path.style.fill = 'url(#' + gradient.id + ')';
                }
              } else {
                layer.path.style.fill = this.color2;
              }
            } else {
              layer.path.style.fill = this.color1;
            }
            const points = layer.points;
            const commands = [];
            commands.push('M', points[0].x, points[0].y);
            for (let pointIndex = 1; pointIndex < points.length; pointIndex += 1) {
              commands.push('C',
                points[(pointIndex + 0) % points.length].cNext.x,
                points[(pointIndex + 0) % points.length].cNext.y,
                points[(pointIndex + 1) % points.length].cPrev.x,
                points[(pointIndex + 1) % points.length].cPrev.y,
                points[(pointIndex + 1) % points.length].x,
                points[(pointIndex + 1) % points.length].y
              );
            }
            commands.push('Z');
            layer.path.setAttribute('d', commands.join(' '));
          }
        //   this.svgText.textContent = this.text;
        //   this.svgText.style.fill = this.textColor;
        }
      
        createPoint(x, y) {
          return {
            x: x,
            y: y,
            ox: x,
            oy: y,
            vx: 0,
            vy: 0,
          };
        }
      
        initOrigins() {
          this.svg.setAttribute('width', this.svgWidth);
          this.svg.setAttribute('height', this.svgHeight);
          for (let layerIndex = 0; layerIndex < this.layers.length; layerIndex++) {
            const layer = this.layers[layerIndex];
            const points = [];
            for (let x = ~~(this.height / 2); x < this.width - ~~(this.height / 2); x += this.gap) {
              points.push(this.createPoint(
                x + this.margin,
                this.margin
              ));
            }
            for (let alpha = ~~(this.height * 1.25); alpha >= 0; alpha -= this.gap) {
              const angle = (Math.PI / ~~(this.height * 1.25)) * alpha;
              points.push(this.createPoint(
                Math.sin(angle) * this.height / 2 + this.margin + this.width - this.height / 2,
                Math.cos(angle) * this.height / 2 + this.margin + this.height / 2
              ));
            }
            for (let x = this.width - ~~(this.height / 2) - 1; x >= ~~(this.height / 2); x -= this.gap) {
              points.push(this.createPoint(
                x + this.margin,
                this.margin + this.height
              ));
            }
            for (let alpha = 0; alpha <= ~~(this.height * 1.25); alpha += this.gap) {
              const angle = (Math.PI / ~~(this.height * 1.25)) * alpha;
              points.push(this.createPoint(
                (this.height - Math.sin(angle) * this.height / 2) + this.margin - this.height / 2,
                Math.cos(angle) * this.height / 2 + this.margin + this.height / 2
              ));
            }
            layer.points = points;
          }
        }
      }
      
      
      const redraw = () => {
        button.initOrigins();
      };
      
      const buttons = document.querySelectorAll( '.liquid-button:not(.lb-inited)' );
      for (let buttonIndex = 0; buttonIndex < buttons.length; buttonIndex++) {
        const button = buttons[buttonIndex];
        button.liquidButton = new LiquidButton(button);
      }
}

const test = {
  user_answers : [],
  current_step: 0,
  set step(a){
    console.log(this)
    if (a > this.current_step){
      $('.step.active').removeClass('active').addClass('prev');
    }else{
      $('.step.active').removeClass('active');
    }
    this.current_step = a;
    if (this.current_step > 0){
      $('.left').removeAttr('disabled');
    }
    if (this.current_step == 0) {
      $('.left').prop("disabled", true);
    }
    if (this.current_step < this.user_answers.length) {
      $('.right').removeAttr('disabled');
    }
    if (this.current_step == this.user_answers.length) {
      $('.right').prop("disabled", true);
    }
    $(`.step[data-step="${this.current_step}"]`).removeClass('prev').addClass('active');
    $('.current-step').text(this.current_step + 1);
    console.log('steped')
  },
  set answer(a){
    this.user_answers[this.current_step] = a;
    this.step = this.current_step + 1;
    this.progress = this.user_answers.length + 1;
    if (this.user_answers.length == 20){
      this.show_result();
    }
  },
  show_result: function(){
    let res_hero_id = this.score;
    let button = `<a href="${this.heroes[res_hero_id].link}" class="btn res-download" download="">
                      <svg class="liquid-button"
                        data-text="скачать"
                        data-text-size="24px"
                        data-margin="20"
                        data-width="140"
                        data-height="46"
                        data-force-factor="0.1"
                        data-layer-1-viscosity="0.5"
                        data-layer-2-viscosity="0.4"
                        data-layer-1-mouse-force="400"
                        data-layer-2-mouse-force="500"
                        data-layer-1-force-limit="1"
                        data-layer-2-force-limit="2"
                        data-color1="#f9f9f9"
                        data-color2="#ffffff"
                        data-color3="#ffffff">
                    </svg>
                </a>`;
    this.heroes.forEach((hero, i) => {
      let template = 
      `<div class="row align-items-center item">
          <div class="col-md-4 d-flex flex-column align-items-center">
              <a href="#" class="res-download" download="">
                  <img class="img-fluid res-img" src="${hero.img}" alt="" srcset="">
              </a>
              <div class="res-name">${hero.name}</div>
              ${res_hero_id == i ? button : ''}
          </div>
          <div class="col-md-8">
              <div class="res-slogan">${hero.slogan}</div>
              <div class="res-text">${hero.text}</div>
          </div>
      </div>`;
      $('.test-result-carousel').append(template);
      
      
    });
    $('.test').fadeOut();
    initLiquidButtons();

    const carousel = $('.test-result-carousel');
    carousel.on('initialized.owl.carousel', function(){
      $('.test-result').addClass('show');
      
      
      
      
    });

    carousel.owlCarousel({
      items: 1,
      dots: false,
      nav: true,
      navText: ['',''],
      loop: true
    });
    carousel.trigger('to.owl.carousel', [res_hero_id, 0]);
    
    
    
  },
  set progress(a){
    $('.progress-bar').css({"width": `${100/20*a}%`});
  },
  get score(){
    let result = {
      score: 0,
      hero_id: 2,
    }
    this.heroes.forEach((hero, i) => {
      let score = hero.answers.reduce((acc, val, i) =>{
        if (val[0] == this.user_answers[i]){
          return acc+2;
        }else if((val.length > 0) && val[1] == this.user_answers[i]){
          return acc+1;
        }else{
          return acc+0;
        }
      }, 0);
      if (score > result.score){
        result.score = score;
        result.hero_id = i
      }
    });
    return result.hero_id;
  },
  heroes: [
    {
      name: 'Космокотик-<br>Капитан',
      answers: [[1, 2], [1, 2], [1, 2], [1, 2], [1, 2], [7, 6], [1], [7, 6], [1], [2, 3], [1, 2], [1], [3, 4], [7], [1], [1, 2], [5, 4], [2, 3], [6, 5], [1, 2]],
      slogan: '"На космическом корабле должен быть порядок"',
      text: "<p>Вы прирожденный лидер и глава космической экспедиции!</p><p>Капитан - ценит порядок и дисциплину.</p><p>Он лучше всех понимает, что правильно и что неправильно для всех остальных котиков. Хотя порой это делает их ужасными упрямцами.</p><p>Котики-Капитаны честные, преданные делу и обладают достоинством.</p><p>Они всегда готовы прийти на помощь команде и дать ценный совет другу.</p><p>С ними не страшно отправить в самое трудное путешествие, ведь у них всегда есть четкий план.</p><p>Космокотик-Капитан всегда усердно работает, а на его груди много звездочек.</p>",
      img: captain,
      link: captain_story,

    },
    {
      name: 'Космокотик-<br>Ученый',
      answers: [[2, 3], [1, 2], [3], [6, 5], [6, 5], [7, 6], [7, 6], [2, 3], [2, 3], [1, 2], [1], [1], [7, 6], [6, 5], [1], [2, 3], [1, 2], [7], [1], [7, 6]],
      slogan: '"Любопытство - не порок!"',
      text: "<p>Никакая космическая экспедиция не обходится без Космокотика-Ученого!</p><p>Он важная часть команды, ведь именно Ученый совершает невероятные открытия.</p><p>Космокотики-ученые гордятся своим интеллектом и знаниями.</p><p>Он постоянно ломает голову над загадками Вселенной и стремится все изучить. И часто все подвергает сомнению.</p><p>Ученый часто выделяется из толпы своим чудаковатым видом.</p><p>Порой он может так замечтаться, что смешно спотыкается обо всё.</p><p>Зато у Космокотика-Ученого богатое воображение и ему никогда не бывает скучно с собой.</p><p>Космос его бесконечно манит своими загадками и тайнами.</p>",
      img: scientist,
      link: scientist_story,
    },
    {
      name: 'Космокотик-<br>Врач',
      answers: [[2, 3], [6, 5], [7, 6], [6, 5], [1, 2], [1, 2], [1, 2], [1, 2], [1, 2], [7], [1], [7], [2, 3], [7], [3, 4], [7], [1, 2], [2, 3], [7, 6], [1]],
      slogan: '"Помогая другим - я счастлив!"',
      text: "<p>Если вас укусил пришелец, ударились об астероид или неудачно приземлились на планету -Космокотик-Врач придет на помощь!</p><p>Всегда доведет дело до конца, очень ответственный и исполнительный, Космокотику-Врачу не страшно доверить лечить даже зубки. </p><p>Ведь он всегда поддержит своего товарища-котика, даже в самой тяжелой космической миссии.</p><p>Космокотик-Врач много трудится, чтобы все котики были здоровы и ему важно не забывать о себе и чаще отдыхать.</p>",
      img: doctor,
      link: doctor_story,
    },
    {
      name: 'Космокотик-<br>Повар',
      answers: [[6,5], [6,5], [6,5], [3,4], [1], [1,2], [1], [1], [6,5], [7], [1], [6,5], [2,3], [2,3], [7,6], [7], [1], [7], [1], [1,2]],
      slogan: '"Люблю делать других счастливыми"',
      text: "<p>У вас был плохой день или экспедиция прошла не успешно?</p><p>Космокотик-Повар мигом поднимет вам настроение вкусными пирожками!</p><p>Он заботится о том, чтобы все Космокотики во всем мире были толстенькими и счастливыми.</p><p> И вся команда любит приходить к нему на кухню за вкусняшками или добрым советом.</p><p> Он не только отличный повар, но и хороший друг для других Космокотиков.</p><p>А его пирожки и слова могут усмирить даже самых страшных пришельцев.</p><p>Космокотик-Повар бывает обидчив, когда его пирожки критикуют.</p><p>Но Космокотик все равно не откажет другим котикам, потому что он очень добрый!</p>",
      img: cook,
      link: cook_story,
    },
    {
      name: 'Космокотик-<br>Пилот',
      answers: [[1], [1, 2], [1, 2], [1], [1, 2], [7,6], [7,6], [1], [2,3], [7,6], [7,6], [1], [1], [1], [7,6], [7], [1], [1], [1], [1]],
      slogan: '"Космокотик-Пилот направляет корабль в полет!"',
      text: "<p>Пойти знакомиться с инопланетянами, залезть на астероид, проверить вон ту черную дыру, кто же первый пойдет на встречу приключениям? Конечно, Космокотик-Пилот!</p><p>Кто-то же должен проложить путь для своей команды и чаще Пилот рискует ради своих друзей-котиков.</p><p>Космокотик-Пилот очень находчивый и сообразительный, поэтому из любой ситуации найдет  выход.</p><p> И с позитивом идет навстречу даже самым опасным приключениям.</p><p>Он очень дружелюбный и способен найти общий язык даже с пришельцем без рта.</p><p>Часто Космокотик-Пилот бывает рассеянным и ленится, но Космокотик-командир помогает ему собраться.</p><p>Котик-Пилот умело летает на космолете, с ним весело отправиться в путешествие!</p>",
      img: pilot,
      link: pilot_story,
    },
    {
      name: 'Космокотик-<br>инженер',
      answers: [[7, 6], [1, 2], [1], [6, 5], [7], [7], [1, 2], [7], [1], [1], [1], [1], [7, 6], [7], [1], [1], [7], [7], [1], [7]],
      slogan: '"Все проверить и перепроверить"',
      text: "<p>У Космокотика-инженера каждый болтик на корабле на своем месте.</p><p>Он мастер на все руки и технический-гений. Инженер может сделать даже сложного робота. </p><p>Космокотик-инженер очень внимателен и усердно работает.</p><p>В школе Космокотов ему было легко учиться, благодаря своему уму и сообразительности.</p><p>Иногда он бывает строгим к другим котикам, но это потому, что любит, чтобы все было идеально. </p><p>И не зря, исправный корабль - залог успешной экспедиции!</p><p>Космокотик-инжернер любит побыть один со своими приборами и схемами.</p><p>У Инженера самые красивые и аккуратные поделки, которыми все восхищаются.</p><p>Часто вместе с Космокотиком-Ученым они разрабатывают удивительные вещи!</p>",
      img: engineer,
      link: engineer_story,
    },
  ],
}

function initTest(){
  $('#start-test').on('click', function(){
    $('.before-test').fadeOut();
    $('.test').slideDown();
  });
  $('.answer').on('click', function(){
    $(this).closest('.step').find('.answer').removeClass('checked')
    $(this).addClass('checked');
    test.answer = $(this).data('answer');
  })
  $('.left').on('click', function(){
    test.step = test.current_step -1 ;
  })
  $('.right').on('click', function(){
    test.step = test.current_step + 1 ;
  })
}

function hoverVideo(){
  const hover = function (e) {
    $(e.target).closest('.col-xl-2').removeClass('col-xl-2').addClass('col-xl-4').siblings('.col-xl-4').removeClass('col-xl-4').addClass('col-xl-2');
  }
  $('.section-5 .video').on('mouseenter', hover);
  let elements = document.querySelectorAll('.section-5 .video');
  Array.from(elements).forEach(function(el){
    el.addEventListener('touchstart', hover);
  });
  
}
function activeMenuItem(){
  $(document).on("scroll", onScroll);
  function onScroll(event){
      var scrollPos = $(document).scrollTop();
      $('.menu .menu__main li:not(.hidden-li) a').each(function () {
          var currLink = $(this);
          var refElement = $(currLink.attr("href"));
          if (refElement.position().top <= scrollPos + window.innerHeight / 2 && refElement.position().top + refElement.outerHeight() > scrollPos) {
              $('.menu .menu__main a').closest('li').removeClass("active");
              currLink.closest('li').addClass("active");
          }
          else{
              currLink.closest('li').removeClass("active");
          }
      });
  }
}

function buttonOverlay(){
  $('.product .buy_button_wrapper').append('<div class="buy_button_overlay"></div>');
  $('.buy_button_overlay').on('click', function(){
    $(this).siblings('.buy_button').trigger('click')
  })
  $('.buy_button').on('click', function(){
    $(this).closest('.buy_button_wrapper').toggleClass('opened')
    var text = $(this).closest('.buy_button_wrapper').hasClass('opened') ? 'закрыть' : 'узнать цену';
    $(this).find('text').text(text);
    $('body').toggleClass('button_overlay_opened')
  })
}
